/* Common styles */
body {
    background-color: #fff;
}

.p-r-none {
    padding-right: 0 !important;
}

.p-r-xs {
    padding-right: 5px !important;
}

.p-r-sm {
    padding-right: 10px !important;
}

.p-r {
    padding-right: 15px !important;
}

.p-r-md {
    padding-right: 20px !important;
}

.p-r-lg {
    padding-right: 30px !important;
}

.p-r-xl {
    padding-right: 40px !important;
}

.p-b-none {
    padding-bottom: 0 !important;
}

.p-b-xs {
    padding-bottom: 5px !important;
}

.p-b-sm {
    padding-bottom: 10px !important;
}

.p-b {
    padding-bottom: 15px !important;
}

.p-b-md {
    padding-bottom: 20px !important;
}

.p-b-lg {
    padding-bottom: 30px !important;
}

.p-b-xl {
    padding-bottom: 40px !important;
}

.m-t-none {
    margin-bottom: 0 !important;
}

.m-t-xs {
    margin-top: 5px !important;
}

.m-t-sm {
    margin-top: 10px !important;
}

.m-t {
    margin-top: 15px !important;
}

.m-t-md {
    margin-top: 20px !important;
}

.m-t-lg {
    margin-top: 30px !important;
}

.m-t-xl {
    margin-top: 40px !important;
}

.m-b-none {
    margin-bottom: 0 !important;
}

.m-b-xs {
    margin-bottom: 5px !important;
}

.m-b-sm {
    margin-bottom: 10px !important;
}

.m-b {
    margin-bottom: 15px !important;
}

.m-b-md {
    margin-bottom: 20px !important;
}

.m-b-lg {
    margin-bottom: 30px !important;
}

.m-b-xl {
    margin-bottom: 40px !important;
}

.show-intercom {
    opacity: 0.2 !important;
    transition: all 1s ease-out !important;
    transform: translateY(-60%) scale(0.8) !important;
}

.move-intercom {
    transition: opacity 1s ease-out !important;
    transform: scale(0.8) !important;
}